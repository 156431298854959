
// Fonts
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font Awesome
@import "node_modules/font-awesome/scss/font-awesome";

// Sidenav
@import "/css/simple-sidebar.css";

// Default
@import "/css/default.css";

// Jquery UI
@import "/css/jquery-ui.min.css";
@import "/css/jquery-ui.structure.min.css";
@import "/css/jquery-ui.theme.min.css";

// Bootstrap Select
@import "/css/bootstrap-select.min.css";

// Bootstrap Datetimepicker
@import "/css/bootstrap-datetimepicker.min.css";
